@import "../../scss/_media-query-mixins.scss";
@import "../../scss/_sass-variables.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 4.25rem;
  background-color: $appBgColor;
  padding: 1.2rem;
  box-shadow: 3px 0px 20px $boxShadowColor;
  // max-width: ;
  position: static;
  max-width: 100%;
  z-index: 1;

  @include sm {
    height: 5.25rem;
    padding: 1.875rem;
  }

  @include xl {
    height: 6.25rem;
  }

  &-icon {
    color: $lightRed;
    transition: 0.3s ease-in-out;
  }

  &-title {
    text-transform: uppercase;
    text-align: center;
    margin-left: $xxlg;
    font-weight: 700;
    font-size: $xlg;
    color: $lightRed;
  }

  &-column-one {
    flex: 1;
    display: flex;
    align-items: center;

    @include md {
      justify-content: space-between;
    }

    &-brand-logo {
      width: 10rem;
      height: 10rem;
      object-fit: contain;

      @include md {
        width: 10rem;
        height: 10rem;
      }
    }
  }

  &-column-two {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $base;
    font-size: $md;
    width: fit-content;

    @include sm {
      flex: 0.5;
      font-size: $base;
    }

    @include lg {
      flex: 1;
      gap: 2.1rem;
    }

    &-docs {
      text-decoration: underline;
      color: $lightRed;
      cursor: pointer;
    }
  }
}
