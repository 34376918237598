@import "./scss/media-query-mixins";
@import "./scss/_sass-variables.scss";

* {
  box-sizing: border-box;
  font-family: $font;

  // customizing the scroll bar
  ::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }

  ::-webkit-scrollbar-track {
    background: $borderColor;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(197, 192, 192);
    border-radius: 3px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

body {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $appBgColor;
  color: $fontColor;
  font-size: $md;
  padding: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100vh;

  @include xl {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
}

// setting button element to have pointer cursor
button {
  cursor: pointer;
}

p {
  margin: 0;
  padding: 0;
}
