@import "../../scss/_media-query-mixins.scss";
@import "../../scss/_sass-variables.scss";

.main {
  display: block;
  width: 100%;
  height: 100%;
  margin: 1rem;
  padding: 1rem 0;
  background-color: $lightRed;
  border-radius: 10px;

  &_logoContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $white;
    font-size: 2rem;
    font-weight: 600;
    margin: 2rem;
    margin-bottom: 4rem;
  }
}

.inactive {
  display: none;
}
