:root {
  --primary-color: #b70303;
}

// change the theme
$theme-colors: (
  "primary": #b70303,
  "danger": #ff4136,
);

// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";
