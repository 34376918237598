@import "../../scss/_media-query-mixins.scss";

.app-layout {
  display: flex;
  flex: 1;
  max-width: 100%;

  &-side {
    width: 15%;
    max-height: 96%;
  }

  &-container,
  &-active {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    max-width: calc(100% - 16%);
    min-width: calc(100% - 16%);
    transition: 0.6s ease-in-out;
    max-height: 99vh;
    overflow: hidden;

    &-contents {
      flex: 1;
      padding: 1.25rem;
      max-width: 100%;
      overflow-y: scroll;

      @include sm {
        padding: 1.5rem;
      }

      @include lg {
        padding: 2.5rem;
      }

      @include xl {
        padding: 2.75rem;
      }
    }
  }
}

.app-layout-active {
  margin-left: 0px;
  max-width: 100%;
  min-width: 100%;
  transition: 0.6s ease-in-out;
}
