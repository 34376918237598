// .side-bar {
//   max-width: 15rem;
//   flex: 1;
//   height: 100vh;
//   overflow-y: auto;
//   background-color: $primary;
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none;

//   &::-webkit-scrollbar {
//     display: none;
//   }

//   &-logo-container {
//     padding: 1.875rem 1.5rem;

//     img {
//       width: 5rem;
//       height: 2rem;
//       object-fit: contain;
//     }

//     .side-bar-logo {
//       &-top {
//         font-size: 1.3rem;
//         color: $light;
//         line-height: 0.5rem;
//         text-transform: uppercase;

//         span {
//           font-size: 1.8rem;
//           text-transform: uppercase;
//           color: $secondary;
//           font-weight: get-weight(medium);
//         }
//       }

//       &-bottom {
//         font-size: 2.5rem;
//         text-transform: uppercase;
//         color: $secondary;
//         font-weight: get-weight(medium);
//       }
//     }
//   }

//   &-nav {
//     margin-bottom: 2.5rem;
//     width: 100%;
//   }
// }

@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.item {
  min-height: 4.5rem;
  margin-top: 0.25rem;
  text-align: left;
  width: 100%;
  padding-left: 1.5rem;

  &:hover {
    transform: scale(1.01);
  }
  &_list {
    list-style: none;
    margin-left: 1rem;
  }
  &_icon {
    font-size: 1.2rem;
  }
  &_link {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      font-weight: 600;
    }
  }
  &_active {
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: $appBgColor;
    padding: 1.5rem;
    color: $lightRed;
    border-radius: 10px 0 0 10px;
  }

  //   &-subroutes {
  //     width: 100%;
  //     margin: 0;
  //     padding: 0;

  //     li {
  //       list-style: none;
  //       width: 100%;
  //       background-color: $white;
  //       padding: 0.8rem 2.375rem;

  //       a {
  //         color: $tertiary;
  //       }
  //       // margin-top: 0.5rem;
  //     }
  //   }
}
