@import "../../scss/media-query-mixins";
@import "../../scss/sass-variables";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  box-shadow: 1px 2px 10px 5px rgba(112, 144, 176, 0.1);
  border-radius: 5px;
  border: 1px solid rgb(214, 212, 212);
  width: min-content;
  padding: 5px;
  color: white;
}

.btn {
  background-color: $white;
  // border: 1px solid $lightRed;
  border: none;
  color: $lightRed;
  cursor: pointer;
  outline: $white;
  padding: 0.2rem 1rem;
  border-radius: 3px;

  &:focus {
    background-color: $white;
    outline: none;
    outline: none;
  }

  &_option {
    background-color: $lightRed;
    padding: 0.2rem;

    &:hover,
    &:focus {
      background-color: $lightRed;
    }
  }
}

.field {
  border: 1px solid rgb(214, 212, 212);
  padding: 0.2rem;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid rgb(214, 212, 212);
    outline: none;
  }
}
