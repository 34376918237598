.App {
  display: flex;
  min-height: 100vh;
}

@keyframes zoom {
  0% {
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: center;
    opacity: 1;
  }
}
